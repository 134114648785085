import React, { Component } from 'react';
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'gatsby';
import axios from 'axios';
import Helmet from 'react-helmet';
import moment from 'moment';
import Layout from '../components/Layout';
import 'react-placeholder/lib/reactPlaceholder.css';
import PageHeading from '../components/PageHeading/PageHeading';
import EventList from '../components/EventList/EventList';

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cap1: [],
      cap2: [],
      cap3: [],
      troTa: [],
      hlv: [],
      other: [],
      ready: false,
      updatedDate: '',
    };
  }

  componentDidMount() {
    const EVENTS_URL =
      'https://cors-veym.herokuapp.com/https://cms.veym.net/items/training_camps?sort=date_start';
    // const EVENTS_URL =
    //   'https://prd.veym.net/webapi/?Function=getEventList&AuthenticatedToken=E8A3BEFC-8B3C-4410-953D-2EE253FBFB2E&Version=1.0';

    axios
      .get(EVENTS_URL)
      .then(res => {
        const {
          data: { data },
        } = res;
        const cap1 = [];
        const cap2 = [];
        const cap3 = [];
        const troTa = [];
        const hlv = [];
        const other = [];
        const updatedDates = [];
        // console.log(data);

        data.forEach(event => {
          if (event.camp_id.startsWith('CI')) {
            cap1.push(event);
          } else if (event.camp_id.startsWith('2')) {
            cap2.push(event);
          } else if (event.camp_id.startsWith('3LD')) {
            cap3.push(event);
          } else if (event.camp_id.startsWith('TT')) {
            troTa.push(event);
          } else if (
            event.camp_id.startsWith('HLV') ||
            event.camp_id.startsWith('TC') ||
            event.camp_id.startsWith('SC')
          ) {
            hlv.push(event);
          } else {
            other.push(event);
          }
          event.date_updated && updatedDates.push(event.date_updated);
          // if all updated dates are null, use latest date_created
          updatedDates.push(event.date_created);
        });

        // get the latest updated or created date
        const moments = updatedDates.map(date => moment(date));

        this.setState({
          cap1,
          cap2,
          cap3,
          troTa,
          hlv,
          other,
          ready: true,
          updatedDate: moment.max(moments),
        });
      })
      .catch(error => {
        console.log(error);
        const cap1 = [];
        const cap2 = [];
        const cap3 = [];
        const troTa = [];
        const hlv = [];
        const other = [];
        const updatedDates = [];
        const moments = updatedDates.map(date => moment(date));

        this.setState({
          cap1,
          cap2,
          cap3,
          troTa,
          hlv,
          other,
          ready: true,
          updatedDate: moment.max(moments),
        });
      });
  }

  render() {
    const {
      cap1,
      cap2,
      cap3,
      hlv,
      troTa,
      other,
      ready,
      updatedDate,
    } = this.state;
    return (
      <Layout>
        <Helmet
          title="VEYM | Training Camps"
          meta={[
            {
              name: 'description',
              content: 'Upcoming Training Camps (Sa Mạc Huấn Luyện)',
            },
          ]}
        />
        <PageHeading>Upcoming Training Camps</PageHeading>
        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Training Camps</BreadcrumbItem>
          </Breadcrumb>
        </Container>
        <br />

        {!ready ? (
          <div>
            <Container>
              <br />
              <h1>Loading Events..</h1>
              <br />
              <br />
            </Container>
          </div>
        ) : null}
        {cap1.length === 0 &&
          cap2.length === 0 &&
          cap3.length === 0 &&
          hlv.length === 0 &&
          troTa.length === 0 &&
          ready && (
            <Container>
              <h3>No upcoming training camps</h3>
            </Container>
          )}

        <EventList
          eventsList={cap1}
          categoryTitle="Sa Mạc HT Cấp 1"
          ready={ready}
        />

        <EventList
          eventsList={cap2}
          categoryTitle="Sa Mạc HT Cấp 2"
          ready={ready}
        />

        <EventList
          eventsList={cap3}
          categoryTitle="Sa Mạc HT Cấp 3"
          ready={ready}
        />

        <EventList
          eventsList={hlv}
          categoryTitle="Sa Mạc Huấn Luyện Viên"
          ready={ready}
        />

        <EventList
          eventsList={troTa}
          categoryTitle="Sa Mạc Trợ Tá"
          ready={ready}
        />

        <EventList eventsList={other} categoryTitle="Other" ready={ready} />

        {ready && (
          <Container>
            <small>
              Last Updated:&nbsp;
              {moment(updatedDate).format('M/D/YY')}
            </small>
          </Container>
        )}
        <br />
        <br />
      </Layout>
    );
  }
}

export default Events;

export const frontmatter = {
  title: 'Training Camps',
  category: 'pages',
  path: '/events',
  description: 'Upcoming Events',
  tags:
    'upcoming events, sa mac, training camps, cap 1, cap 2, cap 3 lanh dao, huan luyen vien, tro ta,  ',
};
