import React from 'react';
import {
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Badge,
  Row,
  Col,
} from 'reactstrap';
import styles from './EventEntry.module.css';
import BadgeC1 from '../../img/Badge-HTC1.jpg';
import BadgeC2A from '../../img/Badge-HTC2-AN.jpg';
import BadgeC2T from '../../img/Badge-HTC2-TN.jpg';
import BadgeC2N from '../../img/Badge-HTC2-NS.jpg';
import BadgeC2H from '../../img/Badge-HTC2-HS.jpg';
import BadgeC3 from '../../img/Badge-HTC3.png';
import BadgeHLV from '../../img/Badge-HLV.jpg';
import khanTroTa from '../../img/TroTa.png';
import veymLogo from '../../img/tnttlogo-1.png';
import moment from 'moment';
import { FiExternalLink } from 'react-icons/fi';

const EventEntry = props => {
  const {
    camp_id,
    name,
    date_start,
    date_end,
    sa_mac_truong,
    sa_mac_pho,
    mien,
    location,
    lien_lac,
    email,
    uses_educenter,
  } = props.event;

  let campBadge = null;
  if (camp_id.startsWith('CI')) {
    campBadge = BadgeC1;
  } else if (camp_id.startsWith('2A')) {
    campBadge = BadgeC2A;
  } else if (camp_id.startsWith('2T')) {
    campBadge = BadgeC2T;
  } else if (camp_id.startsWith('2N')) {
    campBadge = BadgeC2N;
  } else if (camp_id.startsWith('2H')) {
    campBadge = BadgeC2H;
  } else if (camp_id.startsWith('3LD')) {
    campBadge = BadgeC3;
  } else if (
    camp_id.startsWith('HLV') ||
    camp_id.startsWith('TC') ||
    camp_id.startsWith('SC')
  ) {
    campBadge = BadgeHLV;
  } else if (camp_id.startsWith('TT')) {
    campBadge = khanTroTa;
  } else {
    campBadge = veymLogo;
  }

  return (
    <Row className={styles.eventEntry}>
      <Col
        xs={{ size: 12, order: 2 }}
        sm={{ size: 12, order: 12 }}
        md={{ size: 8, order: 1 }}
        lg={{ size: 6, order: 1, offset: 2 }}
      >
        <ListGroupItem>
          <ListGroupItemHeading>
            {name} {camp_id ? ` - (${camp_id})` : null}
            {/* ({camp_id || null} */}
            <br />
          </ListGroupItemHeading>
          <ListGroupItemText>
            {`${moment(date_start).format('M/D/YYYY')} - ${moment(
              date_end
            ).format('M/D/YYYY')}`}
            <br />
            {location}{' '}
            <span className={styles.badge}>
              <Badge color="info">{mien}</Badge>
            </span>
            {sa_mac_truong && sa_mac_pho && <br />}
            {sa_mac_truong && <span>SMT: {sa_mac_truong}</span>}{' '}
            {sa_mac_pho && <div>SMP: {sa_mac_pho}</div>}
            Liên Lạc: {lien_lac}
            <br />
            Email: {email}
            <br />
            <div>
              {uses_educenter && (
                <a
                  href="https://educenter.veym.net"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Badge color="primary" className={styles.educenter}>
                    Educenter <FiExternalLink />
                  </Badge>
                </a>
              )}
            </div>
          </ListGroupItemText>
        </ListGroupItem>
      </Col>
      <Col
        xs={{ size: 12, order: 1 }}
        sm={{ size: 12, order: 1 }}
        md={{ size: 3, order: 2 }}
        className="text-center"
      >
        {campBadge && (
          <img
            src={campBadge}
            className={styles.campBadge}
            alt={camp_id || name}
          />
        )}
      </Col>
    </Row>
  );
};
export default EventEntry;
