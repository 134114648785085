import React, { Component } from 'react';
import { Container, ListGroup, Collapse, Button } from 'reactstrap';
import ReactPlaceholder from 'react-placeholder';
import EventEntry from '../EventEntry/EventEntry';
import styles from './EventList.module.css';

class EventList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { eventsList, categoryTitle, ready } = this.props;

    return (
      <div>
        {eventsList.length !== 0 && (
          <h3 className={styles.eventCategory}>
            <div className={styles.eventCategoryTitle}>
              {categoryTitle}
              &nbsp;&nbsp;&nbsp;
              <Button
                color="link"
                title={
                  this.state.isOpen ? 'Click to collapse' : 'Click to expand'
                }
                onClick={this.toggle}
                className={styles.toggleButton}
              >
                {this.state.isOpen ? (
                  <i className="fa fa-caret-down fa-3x" />
                ) : (
                  <i className="fa fa-caret-right fa-3x" />
                )}
              </Button>
            </div>
          </h3>
        )}
        <Container>
          <ReactPlaceholder
            showLoadingAnimation
            type="text"
            rows={6}
            ready={ready}
          >
            <Collapse isOpen={this.state.isOpen}>
              <ListGroup flush>
                {eventsList.map((event, i) => (
                  <EventEntry
                    key={
                      event.camp_id ? `${event.camp_id}-${i}` : event.TenSaMac
                    }
                    event={event}
                  />
                ))}
              </ListGroup>
            </Collapse>
            <br />
          </ReactPlaceholder>
        </Container>
      </div>
    );
  }
}

export default EventList;
